import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userDetail } from "../Redux/userSlice";
import { rootUrl, userKey } from "../App";

export const useLiveNotifications = () => {
    const [liveNotifications, setLiveNotifications] = useState([]);
    const [unseenNotifications, setUnseenNotifications] = useState([]);
    const [notificationStatus, setNotificationStatus] = useState({});
    const [currentSocket, setCurrentSocket] = useState(null);
    const userInfo = useSelector(userDetail);

    const notificationSound = process.env.PUBLIC_URL + "/Assets/sounds/tap-noti.mp3";

    useEffect(() => {
        if (!userInfo?.user?.username) {
            return;
        }

        const ws = createWebSocket(userInfo.user.noti_username);
        const ws2 = createGroupWebSocket(userInfo.user.noti_username);
        setCurrentSocket([ws, ws2]);

        // console.log([ws, ws2]);

        return () => {
            // closeWebSocket(ws);
            // closeWebSocket(ws2);
            // console.log("WebSocket connections closed");
        }
    }, [userInfo?.user?.username]);

    useEffect(() => {
        const unseenData = liveNotifications?.filter((item) => (((!item?.publish) && (!item?.seen)) || ((item?.publish) && (!item?.is_seen))));
        setUnseenNotifications(unseenData);
    }, [liveNotifications]);

    const createWebSocket = (username) => {
        const ws = new WebSocket(`wss://glo365.app/notification/${username}/`);

        ws.onmessage = (event) => handleWebSocketMessage(event);

        return ws;
    };

    const createGroupWebSocket = (username) => {
        const ws2 = new WebSocket(`wss://glo365.app/group-notification/${username}/`);

        ws2.onmessage = (event) => handleGroupWebSocketMessage(event);

        return ws2;
    };

    const closeWebSocket = (wss) => {
        if (wss.readyState === WebSocket.OPEN) {
            wss.close();
        }
    };

    const handleWebSocketMessage = (event) => {
        // console.log(event);
        const json = JSON.parse(event?.data);
        // console.log(json);

        try {
            const notifications = Array.isArray(json.message) ? json.message : [json.message];
            setLiveNotifications((prevNotifications) => [...notifications, ...prevNotifications]);

            notifications.forEach((notification) => {
                if (!notification.delivered) {
                    pushNotification(notification?.types, {
                        body: notification.notice,
                        icon: 'favicon-32x32.png',
                        badge: 'favicon-16x16.png'
                    });
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleGroupWebSocketMessage = (event) => {
        // console.log(event?.data);
        const json = JSON.parse(event?.data);
        // console.log(json);

        try {
            const notifications = Array.isArray(json.message) ? json.message : [json.message];
            setLiveNotifications((prevNotifications) => [...notifications, ...prevNotifications]);

            const delivarablePromoNotifications = notifications?.filter((notification) => (notification?.is_delivered === false));

            const stringValue = localStorage.getItem(userKey);
            if ((stringValue !== null) && (delivarablePromoNotifications?.length)) {
                const value = JSON.parse(stringValue);
                // console.log(delivarablePromoNotificationss);

                delivarablePromoNotifications?.forEach((notification) => {
                    fetch(`${rootUrl}/notification/delivered-notification/${notification?.id}/`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${value?.value}`,
                        }
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw res;
                            } else {
                                // console.log(res);
                                pushNotification(notification?.types, {
                                    // body: notification?.notice,
                                    body: "",
                                    icon: 'favicon-32x32.png',
                                    badge: 'favicon-16x16.png'
                                });
                            }
                        })
                        .catch((err) => {
                            console.error(err?.detail);
                        });
                })
            }
        } catch (err) {
            console.error(err);
        }
    };

    const updateLiveNotificationStatus = (data) => {
        // console.log(currentSocket, data);
        if ((currentSocket?.[0]) && (!data?.publish)) {
            currentSocket?.[0].send(JSON.stringify(data));
            setLiveNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    (notification.id === data.id) ? { ...notification, seen: true } : notification
                )
            );
            setNotificationStatus(data);
        }
    };

    const updateGroupLiveNotificationStatus = (data) => {
        // console.log(currentSocket?.[1], data);
        if ((currentSocket?.[1]) && (data?.publish)) {
            // currentSocket?.[1].send(JSON.stringify(data));

            const stringValue = localStorage.getItem(userKey);

            if (stringValue !== null) {
                const value = JSON.parse(stringValue);

                fetch(`${rootUrl}/notification/seen-notification/${data?.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${value?.value}`,
                    }
                })
                    .then((res) => {
                        if (!res.ok) {
                            throw res;
                        } else {
                            // setLiveNotifications((prevNotifications) =>
                            //     prevNotifications.map((notification) =>
                            //         (notification?.id === data?.id) ? { ...notification, is_seen: true } : notification
                            //     )
                            // );
                        }
                    })
                    .catch((err) => {
                        console.error(err?.detail);
                    });
            }
            setLiveNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    (notification?.id === data?.id) ? { ...notification, is_seen: true } : notification
                )
            );
            setNotificationStatus(data);
        }
    };

    const deleteNotification = (data) => {
        const stringValue = localStorage.getItem(userKey);

        if (stringValue !== null) {
            const value = JSON.parse(stringValue);

            fetch(`${rootUrl}/notification/notification_delete/${data?.id}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                }
            })
                .then((res) => {
                    if (!res.ok) {
                        throw res;
                    } else {
                        const unseenData = liveNotifications?.filter((seenItem) => seenItem.id !== data?.id);
                        setLiveNotifications(unseenData);
                    }
                })
                .catch((err) => {
                    console.error(err?.detail);
                });
        }
    };

    const pushNotification = (title, options) => {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.ready
                .then((registration) => {
                    const enhancedOptions = {
                        ...options,
                        vibrate: [200, 100, 200],  // Vibration pattern
                        data: {
                            sound: notificationSound || 'default'  // Default sound URL within src
                        }
                    };
                    registration.showNotification(title, enhancedOptions);

                    // Play the notification sound as a result of user interaction
                    playNotificationSound(enhancedOptions.data.sound);
                })
                .catch((error) => console.error(error));
        }
    };

    // Function to play notification sound
    const playNotificationSound = (sound) => {
        const audio = new Audio(sound);
        audio.play().catch((error) => console.error('Sound playback failed:', error));
    };

    return {
        updateLiveNotificationStatus,
        updateGroupLiveNotificationStatus,
        liveNotifications,
        unseenNotifications,
        notificationStatus,
        setNotificationStatus,
        deleteNotification,
    };
};
