import React, { Suspense, useEffect, useRef } from "react";
import "./App.css";
import "./Components/Shared/CustomInputField/CustomInputField.css";

import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import { useThemeColor } from "./hooks/useThemeColor";
import { useStatics } from "./hooks/useStatics";

import { fetchLoginUserData, userDetail, } from "./Redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

import { setInnerLoaderFalse, } from "./Redux/loaderSlice";

import { routesListAuth, routesListWithBackButtonPageName, exceptionalPages } from "./Data/RoutingsData";

// custom-sweet-alert
import CustomSweetAlert2 from "./Components/Shared/CustomSweetAlert/CustomSweetAlert2";
import SuccessDetails from "./Components/Shared/DetailsModals/SuccessDetails/SuccessDetails";

import { AnimatePresence, motion } from "framer-motion";

// layouts
import BaseLayout from "./Layouts/BaseLayout/BaseLayout";
import LayoutWithBackButtonPageName from "./Layouts/LayoutWithBackButtonPageName/LayoutWithBackButtonPageName";
// import { useSliderCardMenu } from "./hooks/useSliderCardMenu";
// import DynamicGames from "./Pages/GamePages/DynamicGames/DynamicGames";
import PrivateRoute from "./Pages/PrivateRoute/PrivateRoute";
import PublicRoute from "./Pages/PrivateRoute/PublicRoute";

// pages
const Home = React.lazy(() => import("./Pages/Home/Home"));

const Loader = React.lazy(() => import("./Components/Shared/Loader/Loader"));
const Progressbar = React.lazy(() => import("./Components/Shared/Progressbar/Progressbar"));
const CustomAlert = React.lazy(() => import("./Components/Shared/CustomAlert/CustomAlert"));
// const MovableHome = React.lazy(() => import("./Components/Shared/MovableHome/MovableHome"));

// export const rootUrl = process.env.REACT_APP_BASE_URL;
export const localUrl = `http://192.168.0.209:81/`;
export const serverUrl = `https://glo365.app`;
// export const rootUrl = localUrl;
export const rootUrl = serverUrl;
export const userKey = "gsagentkeys";

export const extraParamForAgent = "?app=agent";

export const exchangeLaunch_site = "https://9imax.com/";

// export const origin = process.env.REACT_APP_BASE_ORIGIN;
export const RE_DIGIT = new RegExp(/^\d+$/);

function App() {

  const { appBG, progressBar, percentage } = useStatics();
  const { colorMode } = useThemeColor();

  // console.log(process.env.REACT_APP_BASE_URL);

  const user = useSelector(userDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [searchParams,] = useSearchParams();

  // const { sliderCardMenuData } = useSliderCardMenu();

  const location = useLocation();
  const pageURL = location?.pathname.split("/")[1];

  useEffect(() => {
    let stringValue = localStorage.getItem(userKey);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);

      localStorage.setItem(userKey, JSON.stringify({
        is_login: true,
        value: value?.value
      }));
      // dispatch(setInnerLoaderTrue());
    } else {
      if (pageURL !== "forgot-pin") {
        navigate("/login");
      }
    }
  }, [user?.is_login, navigate]);


  useEffect(() => {
    dispatch(fetchLoginUserData());
    dispatch(setInnerLoaderFalse());
  }, [dispatch]);


  const installableBtnRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem("isFirstLoad");
    const ready = (e) => {
      e.preventDefault();
      installableBtnRef.current = e;
    };

    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return (
    <Suspense
      fallback={
        <Loader show={true} />
      }
    >
      <div className={"App row g-0 justify-content-center " + colorMode} style={{ backgroundColor: "orange", backgroundImage: `url(${appBG && rootUrl + appBG[0]?.image})`, }}>
        {
          (
            percentage <= 100 &&
            progressBar
          ) ?
            <Progressbar percentage={percentage} />
            :
            <>
              <div className="centered-main-section" id="main">
                {/* <Loader /> */}
                {/* {
                  (getStartRef) &&
                  <MovableHome />
                } */}
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, x: "-50%" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "50%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <Routes>
                      <Route index
                        element={<BaseLayout><Home installableBtnRef={installableBtnRef} /></BaseLayout>}
                      />

                      {
                        (routesListAuth?.map((item, index) =>
                          (item?.public === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PublicRoute>
                                {item?.component}
                              </PublicRoute>
                            }
                            />
                            :
                            <Route key={item?.id || index} path={item?.path} element={
                              item?.component
                            }
                            />
                        ))
                      }

                      {
                        (routesListWithBackButtonPageName?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                                  {item?.component}
                                </LayoutWithBackButtonPageName>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                              {item?.component}
                            </LayoutWithBackButtonPageName>} />
                        ))
                      }
                      {
                        (exceptionalPages?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <>
                                  {item?.component}
                                </>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<>
                              {item?.component}
                            </>} />
                        ))
                      }
                    </Routes>
                  </motion.div>
                </AnimatePresence>
              </div>
              <CustomAlert />
              <CustomSweetAlert2 />
              <SuccessDetails />
            </>
        }

      </div>
    </Suspense>
  );
}

export default App;
