import React from "react";

import { useLiveNotifications } from "../../hooks/useLiveNotifications";
import NavigationWithBackButtonPageName from "../../Components/Shared/Navigation/NavigationWithBackButtonPageName";

import { motion } from "framer-motion";

const LayoutWithBackButtonPageName = ({ children, pageTitle }) => {

    const { unseenNotifications, } = useLiveNotifications();

    return (
        <div>
            {
                // getStartRef === null ?
                //     <GetStarted setTrueStart={setTrueStart} />
                //     :
                <div className="home">
                    <motion.div
                        initial={{ opacity: 0, x: "-50%" }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: "50%" }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                        <div style={{ minHeight: "calc(100dvh - 63px)" }}>

                            <div className="sticky-top">
                                <NavigationWithBackButtonPageName
                                    pageTitle={pageTitle}
                                    unseenNotifications={unseenNotifications}
                                />
                            </div>
                            {children}
                        </div>
                    </motion.div>
                </div>
            }
        </div>
    );
};

export default LayoutWithBackButtonPageName;