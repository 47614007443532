import React from 'react';


// auth pages
const Credential = React.lazy(() => import('../Pages/Auth/Login/Credential'));

const ForgotPin = React.lazy(() => import('../Pages/Auth/ForgotPin/ForgotPin'));
const ChangePin = React.lazy(() => import('../Pages/ChangePin/ChangePin'));

const Dashboard = React.lazy(() => import('../Pages/Home/Home'));

const Notifications = React.lazy(() => import('../Pages/Notifications/Notifications'));
const UserProfile = React.lazy(() => import('../Pages/UserProfile/UserProfile'));
const EditProfile = React.lazy(() => import('../Pages/EditProfile/EditProfile'));

const Referrals = React.lazy(() => import('../Pages/Referrals/Referrals'));

const AgentGateways = React.lazy(() => import('../Pages//Gateways/Gateways'));
const AgentGatewayStatus = React.lazy(() => import('../Pages//Gateways/GatewayStatus'));
const GatewayStatusDetailsUpdate = React.lazy(() => import('../Pages//Gateways/GatewayStatusDetailsUpdate'));

const Convert = React.lazy(() => import('../Pages/TransactionPages/Convert/Convert.js'));
const Deposit = React.lazy(() => import('../Pages/TransactionPages/Deposit/Deposit.js'));
const DepositTable = React.lazy(() => import('../Pages/TransactionPages/TransactionTables/DepositTable'));

const Transfer = React.lazy(() => import('../Pages/TransactionPages/Transfer/Transfer.js'));
const DepositRequest = React.lazy(() => import('../Pages/TransactionPages/DepositRequest/DepositRequest'));
const WithdrawalRequest = React.lazy(() => import('../Pages/TransactionPages/WithdrawalRequest/WithdrawalRequest'));

const Report = React.lazy(() => import('../Pages/TransactionPages/Report/Report'));
const Revenue = React.lazy(() => import('../Pages/TransactionPages/Revenue/Revenue'));

const AddUser = React.lazy(() => import('../Pages/AddUser/AddUser'));

export const routesListAuth = [
    {
        id: 0,
        name: "Login",
        path: "/login",
        pageTitle: "Login",
        component: <Credential />,
        public: true,
    },
    {
        id: 3,
        name: "Forgot Pin",
        path: "/forgot-pin",
        pageTitle: "Forgot Pin",
        component: <ForgotPin />,
        public: true,
    },
];

export const routesListWithBackButtonPageName = [
    {
        id: 0,
        name: "Dashboard",
        path: "/dashboard",
        pageTitle: "Agent Admin",
        component: <Dashboard />,
        private: true,
    },
    {
        id: 1,
        name: "Agent Profile",
        path: "/agent-profile",
        pageTitle: "Agent Profile",
        component: <UserProfile />,
        private: true,
    },
    // {
    //     id: 2,
    //     name: "Notifications",
    //     path: "/notifications",
    //     pageTitle: "Notifications",
    //     component: <Notifications />,
    //     private: true,
    // },
    {
        id: 3,
        name: "Agent Edit Profile",
        path: "/agent-edit-profile",
        pageTitle: "Agent Edit Profile",
        component: <EditProfile />,
        private: true,
    },
    {
        id: 4,
        name: "Teams",
        path: "/teams",
        pageTitle: "Teams",
        component: <Referrals />,
        private: true,
    },
    {
        id: 5,
        name: "Change Pin",
        path: "/change-pin",
        pageTitle: "Change Pin",
        component: <ChangePin />,
        private: true,
    },
    {
        id: 6,
        name: "Deposit Request",
        path: "/deposit-request",
        pageTitle: "Deposit",
        component: <DepositRequest />,
        private: true,
    },
    {
        id: 7,
        name: "Withdrawal Request",
        path: "/withdrawal-request",
        pageTitle: "Withdrawal",
        component: <WithdrawalRequest />,
        private: true,
    },
    {
        id: 8,
        name: "P2P Transfer",
        path: "/transfer",
        pageTitle: "P2P Transfer",
        component: <Transfer />,
        private: true,
    },
    {
        id: 9,
        name: "Agent Gateways",
        path: "/agent-gateways",
        pageTitle: "Gateway",
        component: <AgentGateways />,
        private: true,
    },
    {
        id: 10,
        name: "Transactions Report",
        path: "/transaction-report",
        pageTitle: "Report",
        component: <Report />,
        private: true,
    },
    {
        id: 24,
        name: "Revenue",
        path: "/revenue",
        pageTitle: "Revenue",
        component: <Revenue />,
        private: true,
    },
    {
        id: 11,
        name: "Gateway Status",
        path: "/gateway-status",
        pageTitle: "Gateway Status",
        component: <AgentGatewayStatus />,
        private: true,
    },
    {
        id: 12,
        name: "Gateway Status Details Update",
        path: "/gateway-status-details-update/:id",
        pageTitle: "Gateway Status Update",
        component: <GatewayStatusDetailsUpdate />,
        private: true,
    },
    {
        id: 13,
        name: "Convert",
        path: "/convert",
        pageTitle: "Convert",
        component: <Convert />,
        private: true,
    },
    {
        id: 14,
        name: "Add User",
        path: "/add-user",
        pageTitle: "Add User",
        component: <AddUser />,
        private: true,
    },
    {
        id: 16,
        name: "Deposit History",
        path: "/deposit-history",
        pageTitle: "History",
        component: <DepositTable />,
        private: true,
    },
];

export const exceptionalPages = [
    {
        id: 2,
        name: "Notifications",
        path: "/notifications",
        pageTitle: "Notifications",
        component: <Notifications />,
        private: true,
    },
    {
        id: 15,
        name: "Deposit",
        path: "/deposit",
        pageTitle: "Deposit",
        component: <Deposit />,
        private: true,
    },
];