import React from "react";
import "./Navigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useStatics } from "../../../hooks/useStatics";
import { userDetail } from "../../../Redux/userSlice";

// import images and icons

import { FaBell } from "react-icons/fa";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { useLiveNotifications } from "../../../hooks/useLiveNotifications";

// const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

// const userIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-icon.svg";
// const userDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-dark-icon.svg";

const Navigation = () => {
    const { colorMode, } = useThemeColor();

    const { logo } = useStatics();

    const navigate = useNavigate();

    const user = useSelector(userDetail);

    const { unseenNotifications, } = useLiveNotifications();

    return (
        <div className="row gx-0 justify-content-between align-items-center py-1 px-2 header">
            <div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start align-items-center">
                {
                    (logo) &&
                    <Link to="/"
                    // onClick={() => setSelectedFragmentID(0)}
                    >
                        <img className="logo"
                            src={logo}
                            alt="logo" />
                    </Link>
                }
                {/* <h1 className="fw-bold text-uppercase ms-2 mb-0 py-0" style={{ color: (colorMode === "light") ? "var(--dark)" : "var(--golden)", textDecoration: "none", fontSize: "0.8rem", whiteSpace: "nowrap", }}>
                    {"Agent Admin"}
                </h1> */}
            </div>
            {
                user?.is_login ?
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">

                        {/* <Link className={true ? "mx-2 bet-notification-number blink-image" : "mx-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--dark)" />

                            <span>{(false) ? "9+" : ""}</span>

                        </Link> */}
                        <Link className={unseenNotifications?.length > 0 ? "mx-2 bet-notification-number blink-image" : "mx-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ''}`}>
                            <FaBell size={22} color="var(--dark)" />
                            {
                                unseenNotifications?.length > 0 &&
                                <span>{(unseenNotifications?.length > 9) ? "9+" : unseenNotifications?.length ? unseenNotifications?.length : ""}</span>
                            }
                        </Link>

                        {/* <div
                            className="nav-user-bg pointer"
                            onClick={() => navigate("/account")}
                        >
                            <img className="p-1" src={(colorMode === "light") ? userIcon : userDarkIcon} alt="user" />
                        </div> */}
                    </div>
                    :
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                LOG IN
                            </button>
                        </Link>
                    </div>
            }
        </div>
    );
};

export default Navigation;