import React, { useEffect, useState } from "react";
import "./Navigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userDetail } from "../../../Redux/userSlice";

// import images and icons

import { FaBell } from "react-icons/fa";
// import { RiQrScan2Line } from "react-icons/ri";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { AiFillLeftCircle } from "react-icons/ai";
import { useLiveNotifications } from "../../../hooks/useLiveNotifications";

// const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

// const userIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-icon.svg";
// const userDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/user-dark-icon.svg";

const NavigationWithBackButtonPageName = ({ pageTitle, state = null, setState = null, unseenNotifications = null }) => {
    const { colorMode, } = useThemeColor();

    const navigate = useNavigate();

    const user = useSelector(userDetail);

    // const { unseenNotifications, } = useLiveNotifications();

    return (
        <div className={((pageTitle === "Notifications") && (unseenNotifications === null)) ? "d-none" : "row gx-0 justify-content-between align-items-center py-1 px-2 header"}>
            < div className="col-3 col-sm-5 d-flex justify-content-start justify-content-md-start align-items-center position-relative" >
                <div className="">
                    <AiFillLeftCircle size={"30"} fill={(colorMode === "light") ? "var(--dark)" : "var(--golden)"} onClick={() => {
                        if (state) {
                            setState();
                        } else {
                            navigate(-1);
                        }
                    }} style={{ cursor: "pointer", marginRight: "5px" }} />
                </div>
                <h1 className="fw-bold text-uppercase mb-0 py-0" style={{ color: (colorMode === "light") ? "var(--dark)" : "var(--golden)", textDecoration: "none", fontSize: "0.8rem", whiteSpace: "nowrap", }}>
                    {pageTitle}
                </h1>
            </div >
            {
                user?.is_login ?
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">

                        {/* < RiQrScan2Line size={25} color={(colorMode === "light") ? "" : "var(--golden)"} /> */}

                        {/* < Link className={true ? "ms-2 bet-notification-number blink-image" : "ms-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--dark)" />

                            <span>{(false) ? "9+" : ""}</span>

                        </Link > */}
                        <Link className={unseenNotifications?.length > 0 ? "ms-2 bet-notification-number blink-image" : "ms-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ''}`}>
                            <FaBell size={22} color="var(--dark)" />
                            {
                                unseenNotifications?.length > 0 &&
                                <span>{(unseenNotifications?.length > 9) ? "9+" : unseenNotifications?.length ? unseenNotifications?.length : ""}</span>
                            }
                        </Link>
                        {/* <div
                            className="nav-user-bg pointer"
                            onClick={() => navigate("/account")}
                        >
                            <img className="p-1" src={(colorMode === "light") ? userIcon : userDarkIcon} alt="user" />
                        </div> */}
                    </div >
                    :
                    <div className="col-9 col-sm-7 d-flex justify-content-end align-items-center">
                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                LOG IN
                            </button>
                        </Link>
                    </div>
            }
        </div >
    );
};

export default NavigationWithBackButtonPageName;