import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
// import { useColorMode } from "theme-ui";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useThemeColor = () => {
    const dispatch = useDispatch();
    // const [colorMode, setColorMode] = useColorMode();
    const [colorMode, setColorMode] = useState("light");


    const changeThemeMode = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        setColorMode((colorMode === "light") ? "dark" : "light");
        dispatch(setInnerLoaderFalse());

    }, [dispatch, colorMode, setColorMode]);

    return {
        colorMode,
        changeThemeMode,
    };
};